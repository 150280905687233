<script lang="ts">
export type ScrollState<T> = {
  availablePages: number[];
  maxPage: number;
  firstFetchEmpty: boolean;
  items: T[];
  scrollPos: number;
  totalCount: number;
};
</script>

<script setup lang="ts" generic="T">
import Grid from 'vue-virtual-scroll-grid';
import { useIntersectionObserver, useScroll, useDebounceFn } from '@vueuse/core';
import { onBeforeUnmount, onMounted, onUpdated, ref, watch, toRefs, computed } from 'vue';
import type { ProfileCard, ProfileImgResponse } from '../../types';
import Card from './ModernflingSupport/Card.vue';
import {
  getSkeletonGrid,
  storageKeys,
  storeValue,
  getStoredValue,
  isUnixExpired
} from '../../utils';
import { useRoute } from 'vue-router';
import { useGlobalsStore, useProfileStore, useSiteConfigStore } from '../../stores';

/**
 * 1. Props and emits
 */
type Props = {
  itemWidth: number;
  itemHeight: number;
  availablePages: number[];
  maxPage: number;
  firstFetchEmpty: boolean;
  items: T[];
  totalCount: number;
  colCount: number;
  gridKey?: number;
  isLoading?: boolean;
};
const props = defineProps<Props>();
const {
  itemWidth,
  itemHeight,
  availablePages,
  maxPage,
  firstFetchEmpty,
  totalCount,
  colCount,
  gridKey
} = toRefs(props);
const items = computed(() => props.items);
const emit = defineEmits<{
  (e: 'fetch'): void;
  (e: 'loadState', state: ScrollState<T>): void;
}>();

const skeletonCard = getSkeletonGrid(1)[0];
const ITEMS_PER_PAGE = useGlobalsStore().constantGlobals.profileGrid.ITEMS_PER_PAGE;
const profileStore = useProfileStore();
/**
 * 2. Infinite scroll
 */

const { y: scrollPos, arrivedState } = useScroll(window, { behavior: 'smooth' });
const gridEnd = ref();
const route = useRoute();
const siteConfigStore = useSiteConfigStore();
const isMembersPage = computed<boolean>(
  () => siteConfigStore.pages?.find((page) => page.key === 'members')?.name === String(route.name)
);
const enableObserver = computed<boolean>(() => {
  if (!isMembersPage.value) {
    return items.value.length >= ITEMS_PER_PAGE || items.value.length === 0;
  } else {
    return !arrivedState.top;
  }
});

useIntersectionObserver(gridEnd, ([{ isIntersecting }]) => {
  if (isIntersecting && enableObserver.value) {
    emit('fetch');
  }
});

function pageProvider() {
  return new Promise<T[]>((resolve) => resolve(items.value));
}

/**
 * 3. Storing and loading state
 */

const STATE_STORAGE_KEY = String(route.name) + storageKeys.profileGrid.scrollState;

function storeState() {
  try {
    const filteredItems = (items.value as ProfileCard[]).filter(
      (x) => !(items.value as ProfileCard[]).find((y) => y.profile_id.split('#')[1] !== undefined)
    );

    const scrollState: ScrollState<ProfileCard> = {
      availablePages: availablePages.value,
      maxPage: maxPage.value,
      firstFetchEmpty: firstFetchEmpty.value,
      items: filteredItems,
      scrollPos: 0,
      totalCount: totalCount.value
    };

    const lastScrollPos = scrollPos.value;
    if (lastScrollPos !== undefined) {
      scrollState.scrollPos = lastScrollPos;
    }

    storeValue(STATE_STORAGE_KEY, scrollState);
  } catch {
    console.error('failed to store state');
  }
}

async function loadState() {
  try {
    const cachedState = getStoredValue(STATE_STORAGE_KEY);
    emit('loadState', cachedState);
    if (!cachedState) return;
    if (cachedState.scrollPos !== 0) {
      // Timeout to let the scroller calculate height first, otherwise scrolling won't work
      setTimeout(() => {
        scrollPos.value = cachedState.scrollPos;
      }, 60);
    }
  } catch {
    //
  }
}

/**
 * 4. Lifecycle hooks
 */

onMounted(() => {
  window.addEventListener('beforeunload', storeState);
  loadState();
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', storeState);
  storeState();
});

onUpdated(() => {
  storeState();
});

/**
 * 5. refetch images
 */
const refetchIDs = ref<string[]>([]);
watch(
  () => items.value,
  () => {
    (items.value as ProfileCard[]).map((item) => {
      if (item.image.url && isUnixExpired(getExpireTime(item.image.url))) {
        refetchIDs.value.push((item as ProfileCard).profile_id);
      }
    });
    if (refetchIDs.value.length > 0) imgRefetch();
  }
);

function getExpireTime(url: string) {
  return new URL(url).searchParams.get('Expires') ?? '0';
}

function imgError(event: Event, id: string) {
  if (!refetchIDs.value.includes(id)) refetchIDs.value.push(id);
  debounceImgError(event);
}

const debounceImgError = useDebounceFn(async (event: Event) => {
  await imgRefetch(event);
}, 250);

async function imgRefetch(event?: Event) {
  if (event !== undefined) {
    if (event === null || event.target === null) return;
    (event.target as HTMLImageElement).onerror = null;
  }
  const refetchList = refetchIDs.value;
  refetchIDs.value = [];
  const images = await profileStore.getProfileImages(refetchList, 500);
  if (images && images.length >= 1) {
    updateImageURLs(images);
  }
  return true;
}
function updateImageURLs(responses: ProfileImgResponse[]) {
  (items.value as ProfileCard[]).forEach((item) => {
    const index = responses.findIndex((response) => response.id === item.profile_id);
    if (index !== -1) {
      item.image = {
        ...item.image,
        url: responses[index].url
      };
    }
  });
}
</script>

<template>
  <Grid
    :key="gridKey"
    :length="items.length"
    :page-size="items.length"
    :page-provider="pageProvider"
    class="grid gap-2"
    :class="{
      'grid-cols-2': colCount === 2,
      'grid-cols-3': colCount === 3,
      'grid-cols-4': colCount === 4,
      'grid-cols-5': colCount === 5,
      'grid-cols-6': colCount === 6
    }"
  >
    <template #probe>
      <div class="item">
        <Card
          :data="{
            profile: skeletonCard,
            isDetailPageCard: false,
            isLoading: true
          }"
          :test-id="null"
        ></Card>
      </div>
    </template>

    <template #placeholder="{ style }">
      <div class="item" :style="style">
        <Card
          :data="{
            profile: skeletonCard,
            isDetailPageCard: false,
            isLoading: true
          }"
          :test-id="null"
        ></Card>
      </div>
    </template>

    <template #default="{ item, style, index }">
      <div class="item" :style="style">
        <Card
          :key="item.profile_id"
          :data="{
            profile: item,
            isDetailPageCard: false,
            isLoading: items.length > 0 || !items || isLoading === true
          }"
          :test-id="'/VirtualGridCard#' + index"
          @refetch="imgError"
        ></Card>
      </div>
    </template>
  </Grid>
  <div id="gridEnd" ref="gridEnd" :key="items.length"></div>
</template>

<style scopedlang="postcss">
.item {
  padding: 0 0;
  width: v-bind(itemWidth);
  height: v-bind(itemHeight);
}
</style>

