import { useChatStore } from '../stores';

interface FlirtButtons {
  [flirt: string]: FlirtButton;
}
interface FlirtButton {
  text: string;
  image: string;
  emoji: string;
}

export const flirtButtons: FlirtButtons = {
  hot: {
    text: "Damn you're hot!",
    image: '/img/common-flirtimages-hot.png',
    emoji: '/img/common-flirtimages-emoji-hot.png'
  },
  marry: {
    text: 'Marry me!',
    image: '/img/common-flirtimages-marry.png',
    emoji: '/img/common-flirtimages-emoji-marry.png'
  },
  cuddle: {
    text: "Let's cuddle!",
    image: '/img/common-flirtimages-cuddle.png',
    emoji: '/img/common-flirtimages-emoji-cuddle.png'
  },
  nearby: {
    text: "I'm nearby. Flirt?",
    image: '/img/common-flirtimages-nearby.png',
    emoji: '/img/common-flirtimages-emoji-nearby.png'
  },
  naughty: {
    text: "Let's get naughty!",
    image: '/img/common-flirtimages-naughty.png',
    emoji: '/img/common-flirtimages-emoji-naughty.png'
  }
};

export async function sendFlirt(id: string, content: string) {
  const chatStore = useChatStore();
  const isFlirted = await chatStore.getIfFlirted(id);
  if (isFlirted.flirted) {
    return {
      success: false,
      reason: 'You already flirted with ' + isFlirted.name
    };
  }
  const response =
    isFlirted.flirted === undefined
      ? { chatID: undefined, error: 500 }
      : await chatStore.sendFlirt(id, content);

  if (response.error || !response.chatID) {
    return {
      success: false,
      reason: response.error?.toString()
    };
  }

  return {
    success: true,
    reason: 'Flirt has been sent! Redirect in '
  };
}

// Temp: func will be refactored when https://harlemnext.atlassian.net/browse/HIL-371 is implemented
export function getFlirtDataFromText(text: string) {
  const flirtData = flirtButtons;
  const flirtDataKey = Object.keys(flirtData).find(
    (flirt: string) => flirtButtons[flirt].text === text
  );
  return flirtDataKey
    ? flirtData[flirtDataKey]
    : {
        text: '',
        image: '',
        emoji: ''
      };
}
